@import "_colors";

.FormDate{
  .calendar{
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap:1px;
    grid-row-gap:1px;
    border:1px solid #ececec;
    background-color:#ececec;

    .day{
      &.filled{
        background-color:#fff;
      }

      &.selected{
        background-color:$mainColor;
        color:#fff;
      }

      aspect-ratio:1/1;
      padding:10px;

    }
  }
}
