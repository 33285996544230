@import "_colors";

.Form{
  display:block;
  width:600px;
  max-width:100%;
  padding:20px;

  label{
    display:block;
    margin-bottom:20px;

    input,textarea{
      width:100%;
      padding:10px;
      margin-top:5px;
      border:1px solid #ccc;
    }

  }

  button[type="submit"]{
    display:block;
    margin:10px;
    padding:10px;
    background-color:$mainColor;
    color:#fff;
    border-radius:6px;
    cursor:pointer;
    padding-left:20px;
    padding-right:20px;
    font-size:16px;
    border:0px;
    margin-left:auto;
  }
}
