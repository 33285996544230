.BusinessDirectory{

  .details{
    text-align:center;

    img{
      max-width:100%;
    }

    p{
      text-align:left;
    }
  }

  .listingCount{
    width:100px;
    text-align:center;
    font-size:20px;
    padding:10px;
    display:inline-block;
    border:1px solid #ececec;
    border-radius:8px;
    margin:5px;

    b{
      display:block;
      font-size:12px;
    }
  }
}
