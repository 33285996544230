.footer{
    width:100%;
    padding:10px;
    background-color:#ececec;
    border-top:1px solid #ccc;

    .container{
      border:0px;
      padding-top:10px !important;
      margin-top:0px !important;
      text-align:center;

      background-color:transparent;
      color:#ccc;

      a{
        color:#423a64;
      }

    }

    .copyright{
      text-align:center;
      color:#333;
      font-size:12px;
      padding:10px;
      display:block;
    }
}
