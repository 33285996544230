.Comments{

  tr,td, th{
    width:100%;
  }
  .comment{
    position:relative;
    padding:10px;
    padding-top:20px;
    padding-bottom:20px;
    font-size:18px;
    line-height:26px;
    overflow:hidden;
    width:100%;
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    &.hasFlags{
      background-color:#ffcccc;

    }

    .label{
      display:inline-block;
      background-color:#000;
      color:#fff;
      padding:1px;
      padding-left:3px;
      padding-right:3px;
      opacity:0.7;
      margin-left:5px;

      &.ban{
        background-color:red;
      }
    }

    .options{
      position:absolute;
      right:10px;
      display:block;
      z-index:2;
      bottom:20px;
      width:30px;
      height:30px;
      border-radius:15px;
      background-color:#fff;
      cursor:pointer;

      svg{
        width:30px;
        height:30px;
        color:#666;
      }

      &:hover{
        svg{
          color:#000;
        }
      }
    }

    &.hidden{
      background-color:#ececec;
      color:#666;
    }

    .comment{
      padding-left:20px;
    }

    .commentUser{
      line-height:18px;
      padding-left:70px;
      min-height:60px;
      display:block;
      font-size:14px;
      position:relative;
      margin-bottom:10px;
      z-index:1;

      .user{
        display:block;
      }

      a.topic{
        display:block;
      }

      i{
        display:block;
        opacity:0.7;
      }

      img{
        position:absolute;
        top:0px;
        left:10px;
        width:50px;
        height:50px;
        border-radius:25px;
        float:left;
        margin-right:10px;
      }
    }
    .stats{
      display:block;
      font-size:14px;
      padding-left:10px;

      div{
        padding-left:10px;
        display:inline-block;

        &.hasFlags{
          color:red;
        }
      }


    }
  }
}
