.loaderScreen{
  position:fixed;
  display:flex;
  justify-content:center;
  align-items:center;
  top:0px;
  left:0px;
  width:100%;
  height:100vh;
  overflow:hidden;
  z-index:999999;
  background:rgba(0,0,0,0.8);
  color:#fff;

  .loaderHolder{
    display:inline-block;
    font-size:18px;
  }
}
