@import "_colors";

.emptyText{
  display:block;
  padding:30px;
  text-align:center;
  color:#666;
  text-transform:uppercase;
}
.List{
  padding:5px;
  text-align:left;

  border-top:1px solid #ececec;
    h3{
      border-left:6px solid $mainColor;
      padding-left:12px;
    }

  .tableHolder{
    table{
      width:100%;
        td, th{
          padding:6px;
          border-bottom:1px solid #ececec;
          text-align:left;
        }
    }

    .loader{
      display:flex;
      text-align:center;
      justify-content: center;
      align-items:center;
      height:200px;
      color:#666;

    }
  }

  .options{
    svg{
      cursor:pointer;
      width:40px;

      color:#666;
    }
  }

  .nextButton, .previousButton{
    margin:10px;
    padding:10px;
    background-color:$mainColor;
    color:#fff;
    border-radius:6px;
    cursor:pointer;
    padding-left:20px;
    padding-right:20px;
    font-size:16px;
    border:0px;
  }
  .previousButton{
    float:left;
    margin-right:0px;
  }

  .nextButton{
    float:right;
    margin-left:10px;
  }

  @media screen and (max-width:600px){


    .tableHolder{
      width:100%;
      overflow-y:visible;
      overflow-x:auto;
    }
    table{
      overflow-x:auto;
      width:100%;
      td, th{
        min-width:300px;
      }
    }
  }
}
