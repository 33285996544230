@import "_colors";

.header{
  text-align:center;

  .container{
    border:0px;
    position:relative;
    margin-top:0px !important;
    background:transparent;
  }

  .headerLogo{
    img{
      width:200px;
      margin-top:20px;
      margin-left:auto;
      margin-right:auto;

    }

    h1{
      margin-top:0px;
      padding-top:5px;
      font-size:18px;
      font-family:'Courier New',monospace;
      font-family:Helvetica,Open Sans,sans-serif;
      letter-spacing:1px;
      text-transform:uppercase;
    }
  }

  .hubhawaii{
    position:absolute;
    right:10px;
    top:20px;
  }

    .menuToggle{
      display:none;
    }

  @media screen and (max-width:600px){
    .headerLogo{
      img{
        margin-top:13px;
        width:180px;
      }

      h1{
        padding-top:0px;
      }
    }

    .hubhawaii{
      position:absolute;
      right:10px;
      top:0px;
    }

    .menuToggle{
      display:block;
    }
  }




}

@media screen and (max-width:600px){

  .header{
    position:fixed;
    top:0px;
    left:0px;
    height:80px;
    z-index:99999;
    width:100%;
    background-color:#fcfcfc;
    border-bottom:1px solid #ececec;

    h1{
      font-size:20px;
      padding-top:0px;
    }

    img{
      margin-top:10px;

    }

    .menuToggle{
      position:absolute;
      left:20px;
      top:12px;
      width:50px;
      height:50px;
      background-color:$mainColor;
      cursor:pointer;
      display:flex;
      justify-content: center;
      align-items: center;

      svg{
        color:#fff;
        width:40px;
      }

      svg.close{
        display:none;
      }

      &.close{
        svg.open{
          display:none;
        }

        svg.close{
          display:block;
        }
      }
    }
  }
}
