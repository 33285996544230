

.hubHawaiiUser{
    display:none;
  }

.hubhawaii{
  color:#000;
  text-align:right;
  line-height:14px;
  color:#ccc;

  width:100%;
  max-width:300px;
  padding:0px;
}

.hubhawaii .profilePicture{
  height:50px;
  width:50px;
  margin:5px;
  margin-top:8px;
  border-radius:25px;
  float:right;
  margin-left:10px;
  cursor:pointer;

}

@media screen and (max-width:700px){
  .hubhawaii .profilePicture{
    margin-top:5px;
  }
}

.hubhawaii .profileName{
  padding-top:11px;
  white-space: nowrap;
  overflow:hidden;
  line-height:20px;
  color:#000;
  text-overflow:ellipsis;
}

.hubhawaii a{
  display:inline-block !important;
  color:#423a64;
  font-size:12px;
  line-height:14px;
	padding:0px !important;

}
