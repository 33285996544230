@import "_colors";

body{
  background-color:#fcfcfc;
}

*{
  box-sizing:border-box;
}

.headerProfile{
  width:70px;
  height:70px;
  position:absolute;
  top:-100px;
  z-index:9999999;
  right:10px;
  border-radius:35px;
  cursor:pointer;
}




.divider{
  border-top:1px solid #ececec;
  width:fit-content;
  width:-webkit-fit-content;
  margin-left:auto;
  margin-right:auto;
}


.container{
  border:1px solid #ececec;
  background-color:#fff;
  display:block;
  width:1200px;
  max-width:100%;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:30px;

  &.main{
    min-height:75vh;

    @media screen and (max-width:600px){
      min-height:85vh;
    }
  }

  .userInfo{
    background-color:#ececec;
    padding:20px;
    text-align:right;
  }

  @media screen and (max-width:600px){
    margin-bottom:0px;
    border-bottom:0px;
  }



  .holder{
    padding:20px;
    padding-left:330px;
    position:relative;
    min-height:80vh;



    .menu{
      list-style:none;
      position:absolute;
      top:0px;
      left:0px;
      margin:0px;
      padding:0px;
      border-right:1px solid #ececec;
      height:100%;
      background-color:#fff;

      li{
        list-style:none;
        margin:0px;
        padding:0px;
        width:300px;

        a{
          display:block;
          padding:20px;
          border-bottom:1px solid #ececec;
          text-decoration:none;
          color:#423a64;

          &.active{
            background-color:#423a64;
            color:#fff;
            font-weight:bold;
          }
        }
      }
    }
  }
}

.loginForm{
  margin-left:auto;
  margin-right:auto;
  padding:20px;

  .screenContentHolder.inline{
    box-shadow:none !important;
  }
}



@media screen and (max-width:600px){

  .desktop-only{
      display:none;
  }

  .container{
    margin-top:65px;
    .holder{
      padding-left:0px;
      padding:10px;


      .menu{
        left:-100%;
        width:100vw;
        position:fixed;
        transition:left 0.3s ease-in-out;
        z-index:4;
        top:80px;
        height:calc(100vh - 70px);
        overflow-y:auto;


        &.show::after{
          content:'';
          display:block;
          background:rgba(255,255,255,0.9);
          position:fixed;
          z-index:2;
          top:0px;
          left:0px;
          width:100vw;
          height:100%;
        }


        &.show{
          left:0px;
        }

        li{
          position:relative;
          z-index:4;
          width:100%;
          text-align:center;
        }
      }
    }


  }
}

.userBlock{
  display:block;
  position:relative;
  min-height:60px;
  padding-left:70px;
  max-width:400px;
  text-align:left;


  img{
    position:absolute;
    top:0px;
    left:10px;
    width:50px;
    height:50px;
    border-radius:25px;
  }

  b{
    display:block;
    padding-top:4px;
  }
}

.button2, .buttonText{
  cursor:pointer;
  border:0px;
  padding:14px;
  padding-left:24px;
  padding-right:24px;
  background:transparent;
  text-transform:uppercase;
  margin:10px;
  transition:all 0.3s ease-in-out;
  width:fit-content;
  -webkit-width:fit-content;
  width:-webkit-fit-content;
  display:block;

  &:hover{
    transform:scale(1.1);
  }

  &.red{
    color:#d32f2f;
  }
}

.intro{
  width:600px;
  max-width:100%;
  margin-left:auto;
  margin-right:auto;
  padding:10px;
  line-height:1.5em;
  padding-top:20px;
  display:block;
}

.sectionParagraph{
  text-decoration:none;
  color:#000;
  line-height:1.8em;
  width:400px;
  max-width:100%;
  margin-left:auto;
  margin-right:auto;
  display:block;
  font-size:14px;
  color:#333;
  padding:15px;
  padding-top:10px;
  padding-bottom:10px;
  cursor:pointer;

  b{
    font-size:1.2em;
    text-decoration:underline;
    display:block;
    width:fit-content;
    width:-webkit-fit-content;
    margin-right:auto;
    transition:all 0.3s ease-in-out;
    color:#423a64;


    svg{
      display:inline-block;
      width:15px;
      position:relative;
      top:1px;
      color:#423a64;

    }
  }

  &:hover{
    b{
      transform:scale(1.1);
    }
  }
}

.deletePrompt{
  display:block;
  text-align:center;
  font-size:16px;
  padding:20px;

  pre{
    display:block;
    text-align:center;
    padding:4px;
    margin:0px;
    font-weight:bold;
    color:#d32f2f;
  }

  input{
    display:block;
    width:280px;
    max-width:100%;
    padding:10px;
    font-size:16px;
    border-radius:5px;
    border:1px solid #ccc;
    margin:5px;
    margin-left:auto;
    text-align:center;
    margin-right:auto;
    margin-top:15px;

  }

  button{
    cursor:pointer;
    border:0px;
    padding:10px;
    background-color:#d32f2f;
    color:#fff;
    border-radius:6px;
    font-weight:bold;
    transition:transform 0.3s ease-in-out;

    &:hover{
      transform:scale(1.1);
    }
  }
}
